<template>
  <el-container style="height: 100vh">
    <!-- 网站头部 -->
    <el-header style="background-color: #4caf50; padding: 10px 20px">
      <el-row type="flex" justify="space-between" align="middle">
        <!-- Logo -->
        <el-col :span="1">
          <img
            src="@/assets/logo.png"
            alt="平台Logo"
            class="logo-img"
            style="width: 50px"
          />
        </el-col>
        <!-- 网站名称 -->
        <el-col :span="8">
          <div
            class="logo"
            style="font-size: 24px; font-weight: bold; color: white"
          >
            传一慕课
          </div>
        </el-col>
        <!-- 导航链接 -->
        <el-col :span="3"><a href="#">首页</a></el-col>
        <!-- 点击课程通过路由跳转 -->
        <el-col :span="1"><router-link to="/course">课程</router-link></el-col>
        <!-- 登录/注册 或 用户信息 -->
        <el-col :span="3" v-if="!isLoggedIn">
          <el-button type="text" @click="showLoginModal">登录</el-button>
          <el-button type="text" @click="showRegisterModal">注册</el-button>
        </el-col>
        <el-col :span="3" v-else>
          <el-avatar :src="userAvatar"></el-avatar>
          <a :href="userCenterUrl">{{ username }}</a>
          <el-button type="text" @click="logout">退出</el-button>
        </el-col>
      </el-row>
    </el-header>

    <!-- 主体内容 -->
    <el-main>
      <!-- 轮播图 -->
      <el-carousel
        :interval="5000"
        arrow="always"
        style="width: 100%; height: 300px; margin-top: 20px"
      >
        <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
          <img :src="item.image" class="carousel-img" />
        </el-carousel-item>
      </el-carousel>

      <!-- 推荐课程 -->
      <div class="recommended-courses">
        <h2>推荐课程</h2>
        <el-row :gutter="20">
          <el-col v-for="(product, index) in products" :key="index" :span="6">
            <el-card :body-style="{ padding: '20px' }">
              <img :src="product.image" class="product-image" />
              <h3>{{ product.title }}</h3>
              <p>折扣价: {{ product.discountedPrice }}</p>
              <p>
                原价: <del>{{ product.originalPrice }}</del>
              </p>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <!-- 登录弹窗 -->
      <el-dialog title="账号登录" :visible.sync="showLogin">
        <el-form @submit.prevent="submitLogin">
          <el-form-item label="用户名">
            <el-input
              v-model="loginUsername"
              placeholder="请输入用户名"
              required
            ></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              type="password"
              v-model="loginPassword"
              placeholder="请输入密码"
              required
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" @click="submitLogin"
              >登录</el-button
            >
          </el-form-item>
          <p class="switch-link">
            还没有账号？
            <a href="javascript:void(0);" @click="switchToRegister">快速注册</a>
          </p>
        </el-form>
      </el-dialog>

      <!-- 注册弹窗 -->
      <el-dialog title="快速注册" :visible.sync="showRegister">
        <el-form @submit.prevent="submitRegister">
          <el-form-item label="用户名">
            <el-input
              v-model="registerUsername"
              placeholder="请输入用户名"
              required
            ></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="nickname" placeholder="名称" required></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              type="password"
              v-model="registerPassword"
              placeholder="请输入密码"
              required
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input
              type="password"
              v-model="confirmPassword"
              placeholder="确认密码"
              required
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              native-type="submit"
              @click="submitRegister"
              >立刻注册</el-button
            >
          </el-form-item>
          <p class="switch-link">
            已有账号？
            <a href="javascript:void(0);" @click="switchToLogin">返回登录</a>
          </p>
        </el-form>
      </el-dialog>
    </el-main>

    <!-- 页脚 -->
    <el-footer
      style="
        background-color: #333;
        color: white;
        text-align: center;
        padding: 20px;
      "
    >
      <p>
        &copy; 2024 在线学习平台 |
        <a href="#" style="color: white">隐私政策</a> |
        <a href="#" style="color: white">联系我们</a>
      </p>
    </el-footer>
  </el-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      searchQuery: "",
      showLogin: false,
      showRegister: false,
      loginUsername: "",
      loginPassword: "",
      registerUsername: "",
      nickname: "",
      registerPassword: "",
      confirmPassword: "",
      isLoggedIn: false,
      userAvatar: "",
      username: "",
      userCenterUrl: "",
      carouselItems: [
        { image: require("@/assets/lbt1.png") },
        { image: require("@/assets/lbt2.png") },
        { image: require("@/assets/lbt3.png") },
        { image: require("@/assets/lbt4.png") },
        { image: require("@/assets/lbt5.png") },
      ],
      products: [
        {
          title: "Springboot+ChatGLM实战",
          image: require("@/assets/lesson1.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "数据分析50+多维度高频场景实战",
          image: require("@/assets/lesson2.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "AI产品独立开发变现实战营",
          image: require("@/assets/lesson3.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "云原生系统精讲与全流程落地实战",
          image: require("@/assets/lesson4.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "实战智能办公助手专为GO工程师设计的AI提升课",
          image: require("@/assets/lesson5.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "ComfyUI可变现项目全流程实战",
          image: require("@/assets/lesson6.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "一人抵一个团队AI赋能十倍提效",
          image: require("@/assets/lesson7.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
        {
          title: "NLP系统精讲大厂案例落地实战",
          image: require("@/assets/lesson8.jpg"),
          discountedPrice: "￥199.00",
          originalPrice: "￥299.00",
        },
      ],
    };
  },
  created() {
    const nickname = localStorage.getItem("nickname");
    if (nickname) {
      this.isLoggedIn = true;
      this.username = nickname;
      // 如果你同时存储了其他信息，可以一起读取
      this.userAvatar = localStorage.getItem("avatar") || "";
    }
  },
  methods: {
    searchCourses() {
      // 搜索课程逻辑
    },
    showLoginModal() {
      this.showLogin = true;
    },
    showRegisterModal() {
      this.showRegister = true;
    },
    submitLogin() {
      // 登录逻辑
      this.$http
        .post(
          "/user/login",
          new URLSearchParams({
            account: this.loginUsername,
            password: this.loginPassword,
          })
        )
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$message.success("登录成功");
            // 假设返回的用户信息在 res.user 中
            const userInfo = res.user;
            // 保存用户信息到本地存储
            localStorage.setItem("nickname", res.data.nickname);
            localStorage.setItem("avatar", res.data.img);
            // 更新页面状态
            this.isLoggedIn = true;
            this.username = res.data.nickname;
            this.userAvatar = res.data.avatar || "";

            // 关闭登录弹窗
            this.showLogin = false;
          } else {
            this.$message.error(res.msg || "用户名或密码错误");
          }
        });
      console.log("登录:", this.loginUsername, this.loginPassword);
    },
    submitRegister() {
      // 注册逻辑
      this.$http
        .post(
          "/user/register",
          new URLSearchParams({
            account: this.registerUsername,
            password: this.registerPassword,
            confirmPassword: this.confirmPassword,
            nickname: this.nickname,
          })
        )
        .then((res) => {
          if (rescode === 1) {
            this.$message.success("注册成功");
            // 注册成功后自动切换到登录界面
            this.showLogin = true;
            this.showRegister = false;
          } else {
            this.$message.error(res.msg || "注册失败");
          }
        });
      console.log(
        "注册:",
        this.registerUsername,
        this.registerPassword,
        this.confirmPassword,
        this.nickname
      );
    },
    switchToRegister() {
      this.showLogin = false;
      this.showRegister = true;
    },
    switchToLogin() {
      this.showRegister = false;
      this.showLogin = true;
    },
    logout() {
      localStorage.removeItem("nickname");
      localStorage.removeItem("avatar");
      this.isLoggedIn = false;
      this.username = "";
      this.userAvatar = "";
      this.$message.success("退出登录成功");
    },
  },
};
</script>

<style scoped>
/* 头部 */
.el-header {
  background: #4caf50;
  color: white;
  padding: 10px 20px;
}

/* 搜索框样式 */
.search-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-container input {
  width: 260px;
  padding: 11px;
  font-size: 15px;
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 45px;
}

.search-container button {
  padding: 10px 10px;
  font-size: 0;
  background-color: #fff;
  color: #4c5eaf;
  border: 1px solid #4c5eaf;
  cursor: pointer;
  border-radius: 50px;
}

.search-container button:hover {
  background-color: #4caf50;
  color: white;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-image {
  width: 100%;
}

.recommended-courses {
  padding: 40px;
}

.recommended-courses h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.switch-link {
  margin-top: 10px;
  text-align: center;
}

.switch-link a {
  color: #4caf50;
  cursor: pointer;
}

.switch-link a:hover {
  text-decoration: underline;
}
</style>

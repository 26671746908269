import { Message } from 'element-ui';

//1.引入axios
import axios from 'axios'

//2.创建axios实例
var http = axios.create({
    baseURL: "/api", // api的base_url
    timeout: 10000 // 请求超时时间
    }
)


//3.请求拦截器
http.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        console.log(err); 
    }
)
//4.响应拦截器
http.interceptors.response.use(
    res => {
            console.log(res); 
            return res.data;
            },
    err => {
        console.error('请求错误:', err);
        if (err.response) {
            switch (err.response.status) {
                case 404:
                    Message.error('请求的接口不存在');
                    break;
                case 500:
                    Message.error(err.response.data.message || '服务器内部错误');
                    break;
                case 401:
                    Message.error('请先登录');
                    break;
                case 403:
                    Message.error('权限不足');
                    break;
                case 400:
                    Message.error('请求参数错误');
                    break;
                default:
                    Message.error('请求失败，请检查网络');
            }
        } else {
            Message.error('请求失败，请检查网络');
        }
        return Promise.reject(err);
    }
)

//5.导出axios实例
export default http;

import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '@/views/IndexView.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component:()=> import('@/views/IndexView.vue')
},
{
  path:'/course',
  name:'course',
  component:()=> import('@/views/CourseView.vue')
},
{
  path:'/login',
  name:'login',
  component:()=> import('@/views/AdminLoginView.vue')
},
{
  path: '/adminMain',
  name: 'adminMain',
  component: () => import('@/views/AdminMainView.vue'),
  children: [
    {
      path: 'AdminMine', 
      name: 'AdminMine',
      component: () => import('@/views/AdminMineView.vue')
    },
    {
      path: 'AdminCourse', 
      name: 'AdminCourse',
      component: () => import('@/views/AdminCourseView.vue')
    },
    {
      path: 'AdminUser', 
      name: 'AdminUser',
      component: () => import('@/views/AdminUserView.vue')
    },
    {
      path: 'AdminInformation', 
      name: 'AdminInformation',
      component: () => import('@/views/AdminInformationView.vue')
    },
  ],
},

{
  path:'/User',
  name:'AdminUser',
  component:()=> import('@/views/AdminUserView.vue')
},
]


const router = new VueRouter({
  mode:"hash",
  routes
})

export default router
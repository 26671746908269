<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
body {
  margin: 0; /* 确保 body 的 margin 为 0 */
  padding: 0; /* 确保 body 的 padding 为 0 */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.el-main{
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 0; /* 修改为 0 */
}
</style>
